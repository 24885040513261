import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import { graphql, Link } from 'gatsby'
import * as React from 'react'
import Bio from '../components/bio'
import PostList from '../components/post-list'
import SEO from '../components/seo'
import Layout from '../layout'
import { INode } from '../types/mdx'
import { ISite } from '../types/site'
import * as styles from './index.module.scss'

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: null } } }
      limit: 100
    ) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            update(formatString: "MMM DD, YYYY")
            title
            tags
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

interface IProps {
  data: {
    site: ISite
    allMdx: {
      edges: INode[]
    }
  }
}

function IndexPage({ data }: IProps) {
  const posts = data.allMdx.edges

  return (
    <Layout>
      {/* thumbnail={} 을 추가하여, 사이트 이미지를 넣자! */}
      <SEO title={`HOME`} />

      <div className={styles.indexWrap}>
        <Bio />

        <div className={styles.indexPostListWrap}>
          <PostList posts={posts} />
          {posts.length < 100 ? null : (
            <div className='show-more-posts'>
              <div className='show-more-btn'>
                <Link to='/search'>
                  <Fa icon={faSearch} />
                  <span>SHOW MORE POSTS</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
