import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import { faUserCircle, faAt, faLink } from '@fortawesome/free-solid-svg-icons'
import ExternalLink from '../external-link'
import BioComments from '../bio-comments'
import BioItem from '../bio-item'
import BioSocials from '../bio-socials'

import { useScroll } from '../../hooks/use-scroll-event'

import * as styles from './index.module.scss'

const bioQuery = graphql`
  query BioQuery {
    site {
      siteMetadata {
        author
        siteUrl
        email
      }
    }
  }
`

function Bio() {
  const {
    site: {
      siteMetadata: { author, siteUrl, email },
    },
  } = useStaticQuery(bioQuery)

  const { isScrollDown } = useScroll()

  return (
    <div className={`${styles.bio} ${isScrollDown ? styles.hidden : ''}`}>
      <BioComments />

      {author && (
        <BioItem prefix={<Fa icon={faUserCircle} />}>
          <ExternalLink
            classNames={styles.authorTag}
            to='https://github.com/eugenejeonme'
            ariaLabel='Author'
          >
            <span>{author}</span>
          </ExternalLink>
        </BioItem>
      )}

      {email && (
        <BioItem prefix={<Fa icon={faAt} />} isMobileHidden>
          <a href={`mailto: ${email}`} aria-label='Email'>
            <span>{email}</span>
          </a>
        </BioItem>
      )}

      {siteUrl && (
        <BioItem prefix={<Fa icon={faLink} />} isMobileHidden>
          <ExternalLink to={siteUrl} ariaLabel={siteUrl}>
            <span>{siteUrl}</span>
          </ExternalLink>
        </BioItem>
      )}

      <BioSocials />
    </div>
  )
}

export default Bio
