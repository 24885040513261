import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactRotatingText from 'react-rotating-text'

import * as styles from './index.module.scss'

const bioCommentsQuery = graphql`
  query {
    site {
      siteMetadata {
        comments {
          strongToken
          prefix
          values
          postfix
          description
        }
      }
    }
  }
`

function BioComments() {
  const {
    site: {
      siteMetadata: { comments },
    },
  } = useStaticQuery(bioCommentsQuery)

  const {
    strongToken,
    prefix,
    values,
    postfix,
    description,
  }: {
    strongToken: string
    prefix: string
    values: string[]
    postfix: string
    description: string
  } = comments

  return (
    <p className={styles.comments}>
      {prefix && <span>{prefix}</span>}

      {values && <ReactRotatingText className={styles.values} items={values} />}

      {postfix && (
        <span>
          {postfix
            .split(strongToken)
            .map((text, index) =>
              index % 2 === 1 ? (
                <strong key={text}>{text}</strong>
              ) : (
                <React.Fragment key={text}>{text}</React.Fragment>
              )
            )}
        </span>
      )}

      {description && <span className={styles.description}>{description}</span>}
    </p>
  )
}

export default BioComments
