import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import { faRss } from '@fortawesome/free-solid-svg-icons'
import {
  faLinkedin,
  faMedium,
  faTwitter,
  faFacebook,
  faInstagram,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import ExternalLink from '../external-link'

import { ISite } from '../../types/site'

import * as styles from './index.module.scss'

const bioSocialsQuery = graphql`
  query BioSocialQuery {
    site {
      siteMetadata {
        siteUrl
        social {
          twitter
          github
          medium
          linkedin
          facebook
          instagram
        }
      }
    }
  }
`

function BioSocials() {
  const {
    site: {
      siteMetadata: { siteUrl, social },
    },
  }: {
    site: ISite
  } = useStaticQuery(bioSocialsQuery)

  const { twitter, github, medium, linkedin, facebook, instagram } = social

  const rssLink = `${siteUrl}/rss.xml`
  const mediumLink = medium ? `https://medium.com/${medium}/` : ''
  const linkedinLink = linkedin
    ? `https://www.linkedin.com/in/${linkedin}/`
    : ''
  const twitterLink = twitter ? `https://twitter.com/${twitter}` : ''
  const facebookLink = facebook ? `https://www.facebook.com/${facebook}` : ''
  const instagramLink = instagram
    ? `https://www.instagram.com/${instagram}`
    : ''
  const githubLink = github ? `https://www.github.com/${github}` : ''

  return (
    <div className={styles.social}>
      <ExternalLink to={rssLink} ariaLabel='Rss'>
        <Fa icon={faRss} className={styles.rss} />
      </ExternalLink>

      {medium && (
        <ExternalLink to={mediumLink} ariaLabel='Medium'>
          <Fa icon={faMedium} className={styles.medium} />
        </ExternalLink>
      )}

      {linkedin && (
        <ExternalLink to={linkedinLink} ariaLabel='LinkedIn'>
          <Fa icon={faLinkedin} className={styles.linkedin} />
        </ExternalLink>
      )}

      {twitter && (
        <ExternalLink to={twitterLink} ariaLabel='Twitter'>
          <Fa icon={faTwitter} className={styles.twitter} />
        </ExternalLink>
      )}

      {facebook && (
        <ExternalLink to={facebookLink} ariaLabel='Facebook'>
          <Fa icon={faFacebook} className={styles.facebook} />
        </ExternalLink>
      )}

      {instagram && (
        <ExternalLink to={instagramLink} ariaLabel='Instagram'>
          <Fa icon={faInstagram} className={styles.instagram} />
        </ExternalLink>
      )}

      {github && (
        <ExternalLink to={githubLink} ariaLabel='Github'>
          <Fa icon={faGithub} className={styles.github} />
        </ExternalLink>
      )}
    </div>
  )
}

export default BioSocials
